#faq-page {
  max-width: 1400px;
  margin: 2rem auto;
  padding: 0 32px;
}

/* Tabs */
#faq-page .nav-tabs {
  display: flex;
  min-height: 2.5em;
  font-size: 1.5rem;
  border: 0 !important;
  margin-bottom: 2em;
}

#faq-page .nav-link {
  padding: 0.5em 1em;
  border: 1px solid var(--main-light-grey);
  border-bottom: 3px solid var(--main-yellow) !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: var(--main-white);
  background-color: var(--main-grey);
  font-family: HelveticaNeueMed;
  flex-grow: 30;
}

#faq-page .nav-link:hover {
  border: 1px solid var(--main-blue) !important;
  border-bottom: 3px solid var(--main-yellow) !important;
  color: var(--main-blue);
}

#faq-page .nav-link.active,
#faq-page .nav-link.active:hover {
  border: 3px solid var(--main-yellow) !important;
  border-bottom: 0 !important;
  color: var(--main-yellow);
  background-color: inherit !important;
  flex-grow: 70;
}

.faq .nav-link:focus {
  outline: none;
}

@media only screen and (max-width: 992px) {
  #faq-page .nav-tabs {
    font-size: 1.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  #faq-page .nav-link,
  #faq-page .nav-link:hover,
  #faq-page .nav-link.active,
  #faq-page .nav-link.active:hover {
    flex-grow: 1;
  }
}

@media only screen and (max-width: 768px) {
  #faq-page {
    padding: 0 16px;
    margin-top: 16px;
  }
}

@media only screen and (max-width: 425px) {
  #faq-page .nav-link {
    padding: 0.5em 0.5em;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}

/* Collapsable */
.collapsable-heading {
  background: none;
  border: none;
  display: flex;
  width: 100%;
  padding: 1rem;
}

.collapsable-heading h4 {
  margin-left: 1rem;
  margin-bottom: 0;
  text-align: left;
}
