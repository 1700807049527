.about-page {
  max-width: 1400px;
  margin: 3rem auto;
  padding: 0 32px;
}

.about-page h2 {
  margin-bottom: 1rem;
}

.about-subheader {
  margin-bottom: 2rem;
}

.about-icon {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin: 1rem 0;
}

.about-mail-icon {
  max-width: 300px;
}

.about-news-icon {
  max-width: 270px;
}

.about-tech-design-icon {
  max-width: 390px;
}

.about-page .about-social-logo {
  margin-right: 20px;
  margin-top: 20px;
}

.about-align-center {
  align-items: center;
  justify-content: center;
}

.about-list {
  margin: 4px 0 4px 32px;
}

.heading-link {
  font-size: 24px;
  font-family: HelveticaNeueBold;
  text-decoration: underline;
  color: var(--main-blue);
}

.heading-link:hover {
  color: var(--main-cream);
}

.row-align-center {
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  /* .about-contact-icon,
  .about-icon {
    max-width: 250px;
  } */

  .about-icon {
    max-width: 250px;
  }

  .about-tech-design-icon {
    max-width: 300px;
  }

  .about-mail-icon {
    max-width: 220px;
  }

  .about-page {
    padding: 0 32px;
  }
}

@media only screen and (max-width: 768px) {
  .about-page {
    padding: 0 16px;
    margin-top: 16px;
  }


  .about-social-logo-row {
    display: flex;
    justify-content: center;
  }

  .about-icon {
    max-width: 190px;
  }

  .about-tech-design-icon {
    max-width: 250px;
  }

  .about-mail-icon {
    max-width: 170px;
  }

  .about-news-icon {
    max-width: 170px;
  }

  .about-order1 {
    order: 1;
  }

  .padding-r {
    padding: 0 16px;
  }

  .about-hr {
    border: 0;
    clear: both;
    display: block;
    background-color: var(--main-yellow);
    height: 3px;
    margin: 50px 0;
  }
}

@media only screen and (max-width: 468px) {
  .about-icon {
    max-width: 120px;
  }

  .about-mail-icon {
    max-width: 120px;
  }

  .about-news-icon {
    max-width: 120px;
  }

  .about-tech-design-icon {
    max-width: 150px;
  }
}