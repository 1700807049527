.project-temp-page {
  height: calc(100vh - 264px);
  margin-top: 4rem;
  margin-bottom: 4rem;
}

@media only screen and (max-width: 360px) {
  .project-temp-page {
    height: unset;
  }
}
