.accordion-container {
  color: var(--main-black);
  overflow: hidden;
  border-radius: 10px;
}

.accordion-header {
  display: flex;
  width: 100%;
  font-family: ArchivoBold;
  font-size: 20px;
  background-color: var(--main-cream);
  border: none;
  padding: 1rem;
}

.accordion-header:focus {
  outline-offset: -2px;
}

.accordion-header .accordion-header-icon {
  height: 100%;
}

.accordion-header-large .accordion-header-icon {
  width: 32px;
  height: 32px;
}

.accordion-section-container .accordion-header-title {
  margin-bottom: 0;
  margin-left: 16px;
  color: var(--main-black);
  text-align: left;
}

.accordion-header:hover {
  background-color: var(--light-yellow);
}

.accordion-header-large {
  padding: 1.2rem;
  height: 32px;
  box-sizing: content-box;
}

.accordion-body {
  background-color: var(--main-grey);
  color: var(--white);
  padding: 1.5rem 3rem;
}

.accordion-body,
.accordion-body span,
.accordion-body div {
  font-size: 18px;
}

.accordion-body-large {
  padding: 2.5rem 4rem;
}

@media only screen and (max-width: 992px) {
  .accordion-header {
    font-size: 16px;
    padding: 1rem;
  }

  .accordion-body {
    padding: 1.5rem;
  }

  .accordion-body,
  .accordion-body span,
  .accordion-body div {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .accordion-header-large {
    height: 20px;
  }

  .accordion-header-large .accordion-header-title {
    line-height: 20px;
  }

  .accordion-header .accordion-header-icon {
    width: 16px;
  }

  .accordion-header-large .accordion-header-icon {
    width: 20px;
    height: 20px;
    vertical-align: unset;
  }
}

@media only screen and (max-width: 425px) {
  .accordion-header-large .accordion-header-title {
    font-size: 16px;
  }
}
