/** Multiple card view **/

.projects-page {
  max-width: 1400px;
  margin: 3rem auto;
  padding: 0 48px;
}

.projects-header {
  width: 50%;
}

.projects-page .flow-images {
  margin: 3em auto;
  display: block;
  padding-right: 1rem;
}

.projects-matched-button {
  margin-left: auto;
}

.projects-join-fb-group-button,
.projects-join-fb-group-button:visited {
  color: inherit;
  text-decoration: none;
}

.preview-card {
  border-radius: 16px;
  margin: 16px 0px;
  box-shadow: 8px 8px 0px 2px rgba(249, 245, 227, 1);
  padding: 4px;
  cursor: pointer;
  background-color: var(--main-grey);
  position: relative;
}

.preview-card.preview-card:not(.select-view-preview-card) {
  height: 300px;
}

.preview-card:hover {
  background-color: var(--main-yellow);
  color: var(--main-black);
}

.preview-card:hover h5 {
  color: var(--main-black);
}

.preview-card-contents {
  max-height: 224px;
  overflow-y: auto;
}

.preview-card-contents::-webkit-scrollbar {
  display: none;
}

.preview-card-contents {
  -ms-overflow-style: none;
}

.preview-subtitle {
  margin-bottom: 16px;
  font-size: 20px;
}

.is-select-preview-card .project-tag,
.preview-card:hover .project-tag {
  color: var(--main-black);
  border: 2px solid var(--main-black);
}

.project-tag {
  border-radius: 8px;
  padding: 4px 8px;
  margin: 4px 8px 4px 0;
  font-size: 14px;
  font-family: HelveticaNeueBold;
  display: inline-block;
  border: 2px solid var(--light-yellow);
  color: var(--light-yellow);
}

.project-tag.project-tag-selected {
  color: var(--main-black);
  background-color: var(--light-yellow);
}

.project-card-tags {
  margin-top: 16px;
}

.project-more-link {
  color: var(--main-blue);
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.preview-card:not(.is-select-preview-card):hover .project-more-link {
  color: var(--dark-blue);
}

.preview-card:not(.is-select-preview-card):hover {
  border: 2px solid var(--dark-blue);
  color: var(--dark-blue);
}

.project-card-description {
  line-height: 1.3rem;
  margin-bottom: 24px;
}

/** Selected card view **/

.projects-back {
  color: var(--main-yellow);
  font-family: ArchivoBold;
  cursor: pointer;
  font-size: 20px;
  margin: 0 0 32px;
  display: inline-block;
}

.projects-back:hover {
  text-decoration: underline;
}

.project-description-card {
  border-radius: 16px;
  padding: 24px;
  background-color: var(--main-grey);
  margin-left: 32px;
  margin-right: -15px;
}

.project-description-close:before,
.project-description-close:after {
  content: '';
  position: absolute;
  width: 24px;
  height: 4px;
  background-color: var(--main-cream);
  border-radius: 2px;
  top: -16px;
  cursor: pointer;
}

.project-description-close:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 16px;
}

.project-description-close:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: -16px;
}

.project-description-close {
  position: relative;
  height: 24px;
  width: 24px;
}

.project-description-subtitle {
  font-size: 24px;
}

.project-loading-spinner {
  margin-top: 3rem;
}

.project-description-note {
  margin: 24px 0;
  font-style: italic;
}

.project-work-button {
  margin-top: 32px;
}

.project-list-panel {
  height: 800px;
  overflow-y: scroll;
  border-radius: 16px;
  padding: 24px;
  background-color: var(--main-cream);
}

.project-list-panel::-webkit-scrollbar {
  display: none;
}

.project-list-panel {
  -ms-overflow-style: none;
}

.select-view-preview-card {
  box-shadow: none;
}

.select-view-preview-card .preview-card-contents {
  padding-bottom: 32px;
  max-height: none;
}

.is-select-preview-card,
.is-select-preview-card:hover {
  background-color: var(--main-blue);
  color: var(--main-cream);
}

.is-select-preview-card:hover h5 {
  color: var(--main-cream);
}

.is-select-preview-card {
  border: 2px solid var(--main-black);
  color: var(--main-black);
}

.projects-newsletter-banner {
  margin-top: 2rem;
}

.projects-filter-bar {
  display: flex;
  position: relative;
  margin: 1rem 0;
  justify-content: space-between;
}

.project-filter-tag {
  cursor: pointer;
  margin: 4px 8px;
}

.project-filter-tag-selected {
  color: var(--main-black);
  background-color: var(--light-yellow);
}

.project-filter-clear {
  cursor: pointer;
  margin: 8px 0 0 16px;
  white-space: nowrap;
}

@media only screen and (max-width: 992px) {
  .projects-matched-order-1 {
    order: 1;
  }

  .projects-matched-order-2 {
    order: 2;
  }

  .projects-matched-button {
    margin: 0 auto;
  }

  .project-description-card {
    margin-left: 0;
  }

  .project-description-close {
    display: none;
  }

  .project-list-panel {
    display: none;
  }

  .projects-filter-bar {
    justify-content: unset;
    display: block;
  }

  .project-filter-clear {
    margin-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .projects-header {
    width: 90%;
  }

  .projects-matched-button {
    margin-left: auto;
    width: 45%
  }

  .projects-page {
    padding: 0 16px;
    margin-top: 16px;
  }

  .preview-card.preview-card:not(.select-view-preview-card) {
    margin-right: 8px;
    height: auto;
  }

  .preview-card-contents {
    max-height: none;
    margin-bottom: 24px;
  }

  .project-description-card {
    padding: 0;
    margin: 0;
  }

  .project-description-card p {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 470px) {

  .projects-matched-button {
    margin-left: auto;
    width: 75%
  }
}
