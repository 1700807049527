.navbar {
  background-color: var(--main-black);
}

.navbar-brand {
  display: flex;
}

.navbar-logo {
  max-width: 36px;
  max-height: 36px;
}

.navbar-logotype {
  color: var(--main-yellow);
  font-size: 16pt;
  font-family: ArchivoBold;
  margin: auto 0 auto 16px;
}

.navbar .navbar-nav .navbar-text.nav-link {
  margin: 0 12px;
  color: var(--main-yellow);
  font-family: HelveticaNeueMed;
  font-size: 20px;
}

.navbar .navbar-nav .navbar-text.nav-link:hover,
.navbar .navbar-nav .navbar-text.nav-link:active {
  color: var(--dark-yellow);
}

.footer {
  display: flex;
  background-color: var(--main-black);
  width: 100%;
  margin: 0;
  padding: 16px 0;
}

.footer-content {
  margin: 0 auto;
}

.footer-icons {
  margin: auto 0 auto 16px;
  height: 24px;
  display: inline-block;
}

.footer-text {
  color: var(--main-cream);
  margin: 0;
  font-size: 24px;
}

.footer-social {
  margin: auto 0;
}

.footer-social-icon {
  margin: 0 8px;
}

@media only screen and (max-width: 1200px) {
  .navbar .navbar-nav .navbar-text.nav-link {
    margin: 0;
  }

  .footer-text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 992px) {
  .footer-icons {
    height: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-logo {
    width: 35px;
  }

  .navbar-logotype {
    margin: auto 0 auto 14px;
  }

  .footer .footer-text {
    font-size: 16px;
    display: block;
  }

  .footer-icons {
    display: flex;
    justify-content: space-evenly;
    margin: 8px auto 0;
  }
}

@media only screen and (max-width: 360px) {
  .navbar-logo {
    max-width: 32px;
  }

  .navbar-logotype {
    font-size: 16px;
  }
}
