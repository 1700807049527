.home-page {
  max-width: 1400px;
  margin: 0 auto 4rem;
  text-align: center;
}

.logoRow {
  flex-direction: column;
}

.logo {
  margin: 32px auto 45px;
  max-width: 325px;
  height: auto;
}

.home-page .logotype,
.home-bio-blurb {
  text-align: center;
}

.home-button-row {
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.home-persona-section-left {
  padding-right: 48px;
}

.home-persona-section-right {
  padding-left: 48px;
}

.home-persona-text {
  font-family: HelveticaNeueMed;
  margin-bottom: 18px;
  display: block;
}

.home-arrow-down {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.home-track-titles {
  margin: 150px 0 55px;
}

.home-track-body {
  margin-top: 55px;
}

.home-comm-partners-icon {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.home-page .flow-images {
  max-width: 70%;
  height: auto;
}

@media only screen and (max-width: 1200px) {
  .logo {
    max-width: 220px;
  }

  .home-persona-section-left {
    padding-right: 15px;
  }

  .home-persona-section-right {
    padding-top: 20px;
    padding-left: 15px;
  }

  .home-persona-text {
    margin-bottom: 10px;
  }

  .home-button-row {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .home-track-titles {
    margin: 100px 0 20px;
  }

  .home-track-body {
    margin-top: 35px;
  }

  .home-comm-partners-icon {
    max-width: 250px;
  }
}

@media only screen and (max-width: 992px) {
  .home-persona-section-right,
  .home-persona-section-left {
    padding: 0 16px;
    margin: 16px 0;
  }
}

@media only screen and (max-width: 768px) {
  .logo {
    max-width: 250px;
  }

  .home-arrow-row {
    display: none;
  }

  .home-track-titles {
    margin-top: 3rem;
  }

  .home-track-body {
    margin-top: 15px;
  }

  .home-comm-partners-icon {
    max-width: 150px;
  }
}

@media only screen and (max-width: 468px) {
  .home-comm-partners-icon {
    max-width: 120px;
  }

  .home-page .flow-images {
    max-width: 300px;
  }
}

@media only screen and (max-width: 360px) {
  .logo {
    margin: 36px auto 45px;
    max-width: 200px;
  }
}
