.matched-projects-header {
  margin-bottom: 1rem;
}

.matched-projects-body {
  margin-bottom: 3.5rem;
}

.matched-projects-row {
  margin: 2rem 0;
}

.matched-projects-back {
  font-family: ArchivoBold;
  cursor: pointer;
  font-size: 20px;
  margin: 0 0 32px;
  display: inline-block;
}

.matched-projects-learn-more {
  display: flex;
  justify-content: flex-end;
}

.carousel-control-prev-icon {
 background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}