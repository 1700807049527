@import './styling/SiteContainer.css';

:root {
  /* theme colors */
  --main-cream: #f9f5e3;
  --main-light-grey: #878680;
  --main-grey: #1f1f1f;
  --main-black: black;
  --main-blue: #6bb4d6;
  --main-yellow: #f5cb5c;
  /* other colors */
  --white: white;
  --dark-blue: #6098bf;
  --light-yellow: #fff0b3;
  --dark-yellow: #e0a800;
  --error-red: #ff5252;
}

@font-face {
  font-family: Archivo;
  src: url('styling/fonts/Archivo-Regular.ttf');
}

@font-face {
  font-family: ArchivoBold;
  src: url('styling/fonts/Archivo-Bold.ttf');
}

@font-face {
  font-family: HelveticaNeueMed;
  src: url('styling/fonts/HelveticaNeue\ Medium.ttf');
}

@font-face {
  font-family: HelveticaNeueLight;
  src: url('styling/fonts/HelveticaNeue\ Light.ttf');
}

@font-face {
  font-family: HelveticaNeueBold;
  src: url('styling/fonts/Helvetica\ Neu\ Bold.ttf');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-black);
  font-family: HelveticaNeueLight, Helvetica, Arial, sans-serif;
  color: var(--main-cream);
}

h1 {
  font-size: 48px;
  color: var(--main-yellow);
  margin: 0;
  font-family: HelveticaNeueBold;
}

h1.logotype {
  font-family: ArchivoBold;
}

h2 {
  font-size: 36px;
  color: var(--main-yellow);
  margin: 0;
  font-family: HelveticaNeueBold;
}

h3 {
  color: var(--main-blue);
  font-size: 32px;
  font-family: HelveticaNeueBold;
}

h3.logotype {
  font-family: Archivo;
}

h4 {
  color: var(--main-cream);
  font-size: 28px;
  font-family: HelveticaNeueBold;
}

h5 {
  color: var(--main-cream);
  font-size: 24px;
  font-family: HelveticaNeueBold;
}

p,
span {
  font-family: HelveticaNeueLight;
  font-size: 20px;
}

a,
a:hover,
a:visited {
  color: var(--main-blue);
}

ul {
  list-style-type: square;
}

li {
  font-family: HelveticaNeueLight;
  font-size: 24px;
}

.primary-button.btn,
.primary-button,
.secondary-button.btn,
.secondary-button {
  padding: 12px 35px;
  border-radius: 30px;
  color: var(--main-black);
  font-size: 24px;
  font-family: HelveticaNeueMed;
}

.primary-button.btn,
.primary-button {
  background-color: var(--main-yellow);
  border-color: var(--main-yellow);
}

.secondary-button.btn,
.secondary-button {
  background-color: var(--main-blue);
  border-color: var(--main-blue);
}

.primary-button.btn:hover,
.primary-button:hover,
.primary-button:not(:disabled):not(.disabled):active {
  background-color: var(--dark-yellow);
  border-color: var(--dark-yellow);
}

.secondary-button.btn:hover,
.secondary-button:hover,
.secondary-button:not(:disabled):not(.disabled):active {
  background-color: var(--dark-blue);
  border-color: var(--dark-blue);
}

.primary-button:disabled .primary-button.btn:disabled,
.secondary-button:disabled,
.secondary-button.btn:disabled {
  background-color: var(--main-light-grey);
  border-color: var(--main-light-grey);
  cursor: not-allowed;
}

hr {
  border: 0;
  clear: both;
  display: block;
  background-color: var(--main-yellow);
  height: 3px;
  margin: 3rem 0;
}

.indent-text {
  text-indent: 2rem;
}
.full-width {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  height: 100%;
  left: 50%;
}

.flow-images {
  max-width: 60%;
  height: auto;
}

.spinner-row {
  margin-top: 3rem;
}

.spinner {
  width: 100px;
  height: 100px;
}

@media only screen and (max-width: 768px) {
  hr {
    margin: 2rem 0;
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 32px;
  }

  h3,
  h5 {
    font-size: 20px;
  }

  h4 {
    font-size: 24px;
  }

  p,
  span,
  .primary-button.btn,
  .secondary-button.btn,
  .primary-button,
  .secondary-button {
    font-size: 16px;
  }
}

@media only screen and (max-width: 425px) {
  .flow-images {
    max-width: 300px;
  }
}

.fade-in-transition-enter {
  opacity: 0;
}
.fade-in-transition-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-in-transition-exit {
  opacity: 1;
}
.fade-in-transition-active {
  opacity: 0;
  transition: opacity 200ms;
}
