#request-page {
  margin: 3rem auto;
}

.request-form-show {
  opacity: 1;
  height: 100%;
  width: 100%;
}

.request-form-hide {
  opacity: 0;
  height: 0;
}

@media only screen and (max-width: 768px) {
  #request-page {
    padding: 0 16px;
    margin-top: 16px;
  }

  #request-page h1 {
    margin-left: 10px;
  }
}
